var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-alert", { attrs: { type: "info", text: "" } }, [
                _vm._v(
                  " Bilgilerinizi görüntülemek için QR kodu okutabilirsiniz. "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex justify-content-center" },
            [
              _c(
                "v-sheet",
                { attrs: { outlined: "", rounded: "" } },
                [
                  !_vm.loading
                    ? _c("vue-qr", {
                        attrs: { text: _vm.qrData, qid: "testid" },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }