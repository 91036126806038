<template>
  <v-container>
    <v-row>
      <v-col>
        <v-alert type="info" text>
          Bilgilerinizi görüntülemek için QR kodu okutabilirsiniz.
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-content-center">
        <v-sheet outlined rounded>
          <vue-qr v-if="!loading" :text="qrData" qid="testid"></vue-qr>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import VueQr from 'vue-qr'
import ApiService from "@/core/services/api.service";

export default {
  name: 'create-qr-code',
  data() {
    return {
      qrSection: false,
      loading: true,
      guid: ''
    }
  },
  created() {
    this.loading = true
    ApiService.setHeader()
    ApiService.post('api/Customer/profile', {}).then(response => {
      this.guid = response.data.ProfileUrl
      this.loading = false
    })
  },
  components: {
    'vue-qr': VueQr
  },
  computed: {
    qrData() {
      return this.guid
    }
  }
}
</script>
